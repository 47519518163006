import { SelectedPlace } from "@/interfaces/searchBlock";
import { PlaceCoordinates } from "./getPlaceCoordinates";

const StartCitiesToUpdate = [
  {
    description: "Bordeaux,",
    coordinates: { computedLatitude: 44.866669, computedLongitude: -0.68333 },
  },
  {
    description: "Lyon,",
    coordinates: { computedLatitude: 45.76667, computedLongitude: 4.88333 },
  },
  {
    description: "Paris,",
    coordinates: {
      computedLatitude: 48.81223018084606,
      computedLongitude: 2.387252483651025,
    },
  },
];

export const computeOneWayStartCoordinates = (
  selectedPlace: SelectedPlace,
  startCoordinates: PlaceCoordinates
): PlaceCoordinates => {
  const isCityToUpdate = StartCitiesToUpdate.find((city) =>
    selectedPlace.description.startsWith(city.description)
  );

  return isCityToUpdate ? isCityToUpdate.coordinates : startCoordinates;
};
