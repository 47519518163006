import { useEffect, useState } from "react";

export default function useScript(src: string) {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    try {
      const script = document.querySelector(`script[src="${src}"]`);
      if (!script) {
        const createdScript: HTMLScriptElement =
          document.createElement("script");
        createdScript.src = src;
        createdScript.async = true;
        createdScript.onload = function () {
          setLoaded(true);
        };
        document.body.appendChild(createdScript);
      } else {
        setLoaded(true);
      }
    } catch (err) {
      console.error(`An error occurred while loading ${src}`);
    }
  }, [src]);

  return loaded;
}
