import { forwardRef } from "react";
import { AutocompleteItem } from "@mantine/core";
import Image from "next/image";
import poweredByGoogle from "@/public/images/powered-by-google.png";

export enum AutocompleteType {
  ADDRESS = "addressGroupLabel",
  AGENCY = "agenciesTitle",
}

export type AutoCompleteItemProps =
  Partial<google.maps.places.AutocompletePrediction> &
    AutocompleteItem & {
      placeId: string | null;
      shouldDisplayGoogleLogo: boolean;
      type: AutocompleteType;
      group: string;
      latitude?: string;
      longitude?: string;
    };

const AutoCompleteItem = forwardRef<HTMLDivElement, AutoCompleteItemProps>(
  (
    {
      value,
      shouldDisplayGoogleLogo,
      placeId,
      ...others
    }: AutoCompleteItemProps,
    ref
  ) => {
    return (
      <div ref={ref} {...others} data-cy={placeId}>
        <div>{value}</div>
        {shouldDisplayGoogleLogo ? (
          <div style={{ position: "absolute", right: 10, bottom: 5 }}>
            <Image
              src={poweredByGoogle}
              alt={"google"}
              layout="fixed"
              width={72}
              height={9}
            />
          </div>
        ) : null}
      </div>
    );
  }
);

AutoCompleteItem.displayName = "AutocompleteItem";

export default AutoCompleteItem;
