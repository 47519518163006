import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";

export const useGoogleMaps = () => {
  const options: Pick<
    google.maps.places.AutocompletionRequest,
    "input" | "componentRestrictions" | "bounds"
  > = {
    input: "gre",
    componentRestrictions: {
      country: ["fr", "mq", "gp", "re", "gf"],
    },
    bounds: {
      south: 45.661,
      west: 5.07,
      north: 45.666,
      east: 5.09,
    },
  };

  // No more than 5 countries allowed
  // fr -> France without dom-tom
  // mq -> Martinique
  // gp -> Guadeloupe
  // re -> Reunion
  // gf -> French Guiana
  // See https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2 for full list

  const googleMapsService = usePlacesService({
    apiKey: process.env.NEXT_PUBLIC_GOOGLE_API ?? "",
    sessionToken: true,
    options,
  });

  return googleMapsService;
};
