import { useEffect } from "react";
import { usePlacesAutocompleteServiceResponse } from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useGoogleMaps } from "./children/autocomplete/hooks/useGoogleMaps";

type LoadGoogleMapsPlacesScriptProps = {
  setGoogleMapsPlacesService: (
    param: usePlacesAutocompleteServiceResponse
  ) => void;
};

export const LoadGoogleMapsPlacesScript = ({
  setGoogleMapsPlacesService,
}: LoadGoogleMapsPlacesScriptProps) => {
  const googleMapsPlacesService = useGoogleMaps();
  useEffect(() => {
    setGoogleMapsPlacesService(googleMapsPlacesService);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [
    JSON.stringify(googleMapsPlacesService.placePredictions),
    googleMapsPlacesService.isPlacePredictionsLoading,
    googleMapsPlacesService.placesService,
  ]);
  /* eslint-enable react-hooks/exhaustive-deps */

  return null;
};
