import { DateTimePickers } from "@/interfaces/searchBlock";

export type DateTimePickerInput = {
  name: DateTimePickers;
  locale: string;
  datePickerAriaLabel: string;
  datePickerPlaceholder: string;
  selectAriaLabel: string;
  selectPlaceholder: string;
};

export const dateTimePickers = [
  {
    name: DateTimePickers.START_DATE,
    locale: "fr",
    datePickerAriaLabel: "searchBlockDatePickerOneWayStartAriaLabel",
    datePickerPlaceholder: "searchBlockDatePickerOneWayStartPlaceholder",
    selectAriaLabel: "searchBlockSelectAriaLabel",
    selectPlaceholder: "searchBlockSelectPlaceholder",
  },
  {
    name: DateTimePickers.END_DATE,
    locale: "fr",
    datePickerAriaLabel: "searchBlockDatePickerOneWayEndAriaLabel",
    datePickerPlaceholder: "searchBlockDatePickerOneWayEndPlaceholder",
    selectAriaLabel: "searchBlockSelectAriaLabel",
    selectPlaceholder: "searchBlockSelectPlaceholder",
  },
];
