export type GuaranteedCategory = {
  title: string;
  subtitle: string;
  categoryCode: string;
};

export const multiResultCityReferential = new Set([
  "PARIS, FRANCE",
  "MARSEILLE, FRANCE",
  "MONTPELLIER, FRANCE",
  "LYON, FRANCE",
  "GRENOBLE, FRANCE",
  "NICE, FRANCE",
  "PERPIGNAN, FRANCE",
  "TOULOUSE, FRANCE",
  "BÉZIERS, FRANCE",
  "CANNES, FRANCE",
  "LILLE, FRANCE",
  "STRASBOURG, FRANCE",
  "AUBERGENVILLE, FRANCE",
  "SAINT-DENIS, FRANCE",
  "AVIGNON, FRANCE",
  "BORDEAUX, FRANCE",
  "BOURGES, FRANCE",
  "BREST, FRANCE",
  "CLERMONT-FERRAND, FRANCE",
  "DIJON, FRANCE",
  "MAUGUIO, FRANCE",
  "MONTÉLIMAR, FRANCE",
  "MULHOUSE, FRANCE",
  "NANTES, FRANCE",
  "NÎMES, FRANCE",
  "NIORT, FRANCE",
  "RENNES, FRANCE",
  "TOULON, FRANCE",
  "TOURS, FRANCE",
  "BASTIA, FRANCE",
  "PARIS 08 - BATIGNOLLES",
  "PARIS 11 - BASTILLE",
  "PARIS 12 - BERCY",
  "PARIS 12 - NATION",
  "PARIS 13 - GOBELINS",
  "PARIS 15 - BRANCION",
  "PARIS 14 - MONTPARNASSE",
  "PARIS 15 - BRANCION (CORNER)",
  "PARIS 15 - CONVENTION",
  "PARIS 17 - MAILLOT",
  "PARIS 19  - JAURES",
  "CHAMPIGNY SUR MARNE",
  "LEVALLOIS PERRET",
  "ASNIERES SUR SEINE",
  "BOULOGNE BILLANCOURT REINE",
  "ISSY LES MOULINEAUX",
  "PUTEAUX - LA DEFENSE",
  "RUEIL MALMAISON",
]);
