import dayjs from "dayjs";
import { UseFormSetValue } from "react-hook-form";
import {
  DateTimePickers,
  FormattedDate,
  FormValues,
} from "@/interfaces/searchBlock";
import {
  MAX_DIFFERENCE_ONE_WAY_DATES_THRESHOLD,
  MAX_DIFFERENCE_ROUND_TRIP_DATES_THRESHOLD,
} from "../../children/dateTimePicker/constants/dateTimePickerConstants";
import { DateTimePickersErrors } from "../../children/dateTimePicker/constants/dateTimePickerConstants";
import {
  areSameDay,
  convertCurrentTimeToNearestHalfHour,
  differenceBetweenDatesInDays,
  formatDateAndTimeToDateTime,
} from "../../children/dateTimePicker/helpers/timeSelector";
import { isDateFilled, isTimeFilled } from "../../helpers/isInputFilled";
import { ValidationMethods } from "../isFormValidAndDisplayErrors";

const DEFAULT_START_TIME = "09:00";
const DEFAULT_END_TIME = "18:00";

type SetDateTimePickerErrorProps = {
  inputName: DateTimePickers;
  type: DateTimePickersErrors;
  messageId: string;
  validationMethods: Pick<ValidationMethods, "setError">;
};

export const setDateTimePickerError = ({
  inputName,
  type,
  messageId,
  validationMethods,
}: SetDateTimePickerErrorProps) => {
  const { setError } = validationMethods;
  setError(inputName, {
    type: type,
    message: messageId,
  });
};

type HandleOnChangeDateTimePickerErrorProps = {
  inputName?: DateTimePickers;
  setValue: UseFormSetValue<FormValues>;
  isOneWayTrip: boolean;
  validationMethods: Pick<
    ValidationMethods,
    "clearErrors" | "setError" | "getValues"
  >;
};

export const handleOnChangeDateTimePickerError = ({
  inputName,
  setValue,
  isOneWayTrip,
  validationMethods,
}: HandleOnChangeDateTimePickerErrorProps) => {
  const { clearErrors, getValues } = validationMethods;

  const startDate = getValues(DateTimePickers.START_DATE);
  const endDate = getValues(DateTimePickers.END_DATE);

  const isStartDateSelected = isDateFilled(startDate);
  const isStartTimeSelected = isTimeFilled(startDate);

  const isEndDateSelected = isDateFilled(endDate);
  const isEndTimeSelected = isTimeFilled(endDate);

  const areDatesSelected = isStartDateSelected && isEndDateSelected;
  const areTimesSelected = isStartTimeSelected && isEndTimeSelected;

  if (areDatesSelected) {
    const otherInputName =
      inputName === DateTimePickers.START_DATE
        ? DateTimePickers.END_DATE
        : DateTimePickers.START_DATE;
    let startDateTime = dayjs(startDate.date);
    let endDateTime = dayjs(endDate.date);

    if (areTimesSelected) {
      startDateTime = dayjs(formatDateAndTimeToDateTime(startDate));
      endDateTime = dayjs(formatDateAndTimeToDateTime(endDate));

      if (startDateTime.isSame(endDateTime)) {
        setValue(otherInputName, { date: null, time: null });

        return;
      }
    }

    if (startDateTime.isAfter(endDateTime)) {
      setValue(otherInputName, { date: null, time: null });

      return;
    }

    if (
      isDifferenceBetweenDatesError(
        startDate,
        endDate,
        isOneWayTrip,
        validationMethods
      )
    ) {
      return;
    }
  }

  const dateTimePickersErrors: Record<DateTimePickers, DateTimePickersErrors> =
    {
      [DateTimePickers.START_DATE]: computeDateTimeErrors(
        isStartDateSelected,
        isStartTimeSelected
      ),
      [DateTimePickers.END_DATE]: computeDateTimeErrors(
        isEndDateSelected,
        isEndTimeSelected
      ),
    };

  Object.entries(dateTimePickersErrors).map(([key, error]) => {
    const inputKey = key as DateTimePickers;

    switch (error) {
      case DateTimePickersErrors.DATE_TIME:
        break;

      case DateTimePickersErrors.NO_ERROR:
      default:
        clearErrors(inputKey);
        break;
    }
  });
};

export const isDifferenceBetweenDatesError = (
  startDate: FormattedDate,
  endDate: FormattedDate,
  isOneWayTrip: boolean,
  validationMethods: Pick<ValidationMethods, "setError" | "clearErrors">
) => {
  const { clearErrors } = validationMethods;

  const MAX_DIFFERENCE_BETWEEND_DATES_THRESHOLD = isOneWayTrip
    ? MAX_DIFFERENCE_ONE_WAY_DATES_THRESHOLD
    : MAX_DIFFERENCE_ROUND_TRIP_DATES_THRESHOLD;

  const differenceBetweenDates = differenceBetweenDatesInDays(
    startDate,
    endDate
  );

  const isDifferenceBetweenDatesAboveThreshold =
    differenceBetweenDates > MAX_DIFFERENCE_BETWEEND_DATES_THRESHOLD;

  if (isDifferenceBetweenDatesAboveThreshold) {
    clearErrors([DateTimePickers.START_DATE, DateTimePickers.END_DATE]);

    setDateTimePickerError({
      inputName: DateTimePickers.END_DATE,
      type: DateTimePickersErrors.DATE_TIME_ERROR_ON_END_DATEPICKER,
      messageId: isOneWayTrip
        ? "dateTimePickerOneWayDateTimeEndDateError"
        : "dateTimePickerRoundTripDateTimeEndDateError",
      validationMethods,
    });
  }

  return isDifferenceBetweenDatesAboveThreshold;
};

export const isDateInPast = (date?: Date | null) => {
  return date && date < new Date();
};

export const computeDateTimeErrors = (
  isDateSelected: boolean,
  isTimeSelected: boolean,
  date?: Date
) => {
  if (!isDateSelected && isTimeSelected) {
    return DateTimePickersErrors.DATE;
  }

  if (isDateSelected && !isTimeSelected) {
    return DateTimePickersErrors.TIME;
  }

  if (!isDateSelected && !isTimeSelected) {
    return DateTimePickersErrors.DATE_TIME;
  }

  if (isDateInPast(date)) {
    return DateTimePickersErrors.DATE_IN_PAST;
  }

  return DateTimePickersErrors.NO_ERROR;
};

type SetDefaultTimeProps = {
  inputName: DateTimePickers;
  setValue: UseFormSetValue<FormValues>;
  validationMethods: ValidationMethods;
};

export const setDefaultTime = ({
  inputName,
  setValue,
  validationMethods,
}: SetDefaultTimeProps) => {
  const { getValues } = validationMethods;

  const isStartDatePicker = inputName === DateTimePickers.START_DATE;

  const startDate = getValues(DateTimePickers.START_DATE);
  const endDate = getValues(DateTimePickers.END_DATE);

  const isStartDateSelected = isDateFilled(startDate);
  const isStartTimeSelected = isTimeFilled(startDate);

  const isEndDateSelected = isDateFilled(endDate);

  const areDatesSelected = isStartDateSelected && isEndDateSelected;

  if (isStartDateSelected && isStartDatePicker) {
    const isStartDateSelectedToday = areSameDay(startDate.date, new Date());

    const defaultStartTime = isStartDateSelectedToday
      ? convertCurrentTimeToNearestHalfHour()
      : DEFAULT_START_TIME;

    setValue(inputName, { date: startDate.date, time: defaultStartTime });

    return;
  }

  if (areDatesSelected && isStartTimeSelected && !isStartDatePicker) {
    const areStartDateAndEndDateSameDay = areSameDay(
      startDate.date,
      endDate.date
    );

    if (areStartDateAndEndDateSameDay && startDate.time < DEFAULT_END_TIME) {
      setValue(inputName, { date: endDate.date, time: DEFAULT_END_TIME });
    } else if (!areStartDateAndEndDateSameDay) {
      setValue(inputName, { date: endDate.date, time: startDate.time });
    }

    return;
  }
};
