import { UseFormReturn } from "react-hook-form";
import {
  DateTimePickers,
  FormValues,
  Trips,
  ValidFormValues,
} from "@/interfaces/searchBlock";
import { DateTimePickersErrors } from "../children/dateTimePicker/constants/dateTimePickerConstants";
import {
  computeDateTimeErrors,
  isDifferenceBetweenDatesError,
  setDateTimePickerError,
} from "./dateTimePicker/dateTimePickerValidationService";
import { isDateFilled, isTimeFilled } from "../helpers/isInputFilled";
import { formatDateAndTimeToDateTime } from "../children/dateTimePicker/helpers/timeSelector";
import { isAgencyPlace } from "../helpers/isAgencyPlace";

export type ValidationMethods = Pick<
  UseFormReturn<FormValues>,
  "getValues" | "getFieldState" | "setError" | "clearErrors" | "formState"
>;

type ValidateAutocompleteInputs = {
  formValues: FormValues;
  validationMethods: Pick<ValidationMethods, "setError">;
};

const validateAutocompleteInputs = ({
  formValues,
  validationMethods,
}: ValidateAutocompleteInputs) => {
  const { setError } = validationMethods;
  let areAutocompletesValid = true;

  const autocompleteInputs = formValues.isOneWayTrip
    ? [Trips.ONE_WAY_START, Trips.ONE_WAY_END]
    : [Trips.ROUND_TRIP];

  autocompleteInputs.forEach((inputName) => {
    const selectedPlace = formValues[inputName];
    const placeId =
      selectedPlace && isAgencyPlace(selectedPlace)
        ? undefined
        : selectedPlace?.placeId;
    const isPlaceSelected =
      (placeId !== null &&
        placeId !== undefined &&
        selectedPlace !== undefined) ||
      selectedPlace?.isPrefilled ||
      selectedPlace?.isAgency;

    if (!isPlaceSelected) {
      areAutocompletesValid = false;

      setError(inputName, {
        type: "validate",
        message: "searchBlockAutocompleteError",
      });
    }
  });

  return areAutocompletesValid;
};

type ValidateDateTimePickersInputs = {
  formValues: FormValues;
  validationMethods: Pick<ValidationMethods, "setError" | "clearErrors">;
};

const validateDateTimePickersInputs = ({
  formValues,
  validationMethods,
}: ValidateDateTimePickersInputs) => {
  const { clearErrors } = validationMethods;

  const startDate = formValues.startDate;
  const endDate = formValues.endDate;

  const isStartDateSelected = isDateFilled(startDate);
  const isStartTimeSelected = isTimeFilled(startDate);

  const isEndDateSelected = isDateFilled(endDate);
  const isEndTimeSelected = isTimeFilled(endDate);

  const areDatesSelected = isStartDateSelected && isEndDateSelected;

  if (
    areDatesSelected &&
    isDifferenceBetweenDatesError(
      startDate,
      endDate,
      formValues.isOneWayTrip,
      validationMethods
    )
  ) {
    return false;
  }

  const dateTimePickersErrors: Record<DateTimePickers, DateTimePickersErrors> =
    {
      [DateTimePickers.START_DATE]: computeDateTimeErrors(
        isStartDateSelected,
        isStartTimeSelected,
        isStartDateSelected && isStartTimeSelected
          ? new Date(formatDateAndTimeToDateTime(startDate))
          : undefined
      ),
      [DateTimePickers.END_DATE]: computeDateTimeErrors(
        isEndDateSelected,
        isEndTimeSelected,
        isEndDateSelected && isEndTimeSelected
          ? new Date(formatDateAndTimeToDateTime(endDate))
          : undefined
      ),
    };

  let isValid = true;
  Object.entries(dateTimePickersErrors).forEach(([key, error]) => {
    const inputName = key as DateTimePickers;
    clearErrors(inputName);

    switch (error) {
      case DateTimePickersErrors.DATE:
        setDateTimePickerError({
          inputName,
          type: DateTimePickersErrors.DATE,
          messageId: "dateTimePickerDateError",
          validationMethods,
        });
        isValid = false;
        break;

      case DateTimePickersErrors.TIME:
        setDateTimePickerError({
          inputName,
          type: DateTimePickersErrors.TIME,
          messageId: "dateTimePickerTimeError",
          validationMethods,
        });

        isValid = false;
        break;

      case DateTimePickersErrors.DATE_TIME:
        setDateTimePickerError({
          inputName,
          type: DateTimePickersErrors.DATE_TIME,
          messageId: "dateTimePickerDateTimeError",
          validationMethods,
        });

        isValid = false;
        break;

      case DateTimePickersErrors.DATE_IN_PAST:
        setDateTimePickerError({
          inputName,
          type: DateTimePickersErrors.DATE_IN_PAST,
          messageId: "dateTimePickerTimeError",
          validationMethods,
        });

        isValid = false;
        break;

      default:
        break;
    }
  });

  return isValid;
};

export const isFormValidAndDisplayErrors = (
  formValues: FormValues,
  validationMethods: ValidationMethods
): formValues is ValidFormValues => {
  const areAutocompletesValid = validateAutocompleteInputs({
    formValues,
    validationMethods,
  });

  const areDateTimePickersValid = validateDateTimePickersInputs({
    formValues,
    validationMethods,
  });

  return areAutocompletesValid && areDateTimePickersValid;
};
