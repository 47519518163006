import { Control, UseFormSetValue } from "react-hook-form";
import { RefObject, Suspense, useState } from "react";
import dynamic from "next/dynamic";
import { DateTimePickers, FormValues } from "@/interfaces/searchBlock";
import { InputWithAutofocus } from "../../helpers/autoFocusNextInput";
import { ValidationMethods } from "../../validation/isFormValidAndDisplayErrors";
import { DateTimePickerInput } from "./context/dateTimePickerInputs";
import FakeDateTimePickers from "./FakeDateTimePickers/FakeDateTimePickers";

const DynamicDateTimePickers = dynamic(
  () =>
    import(
      "@/components/common/searchBlock/children/dateTimePicker/DateTimePickers"
    ),
  {
    suspense: true,
  }
);

const DateTimePickersWithLazyLoading = ({
  dateTimePickers,
  isRealDateTimePicker,
  control,
  setValue,
  validationMethods,
  autoFocusNextInput,
  startDateRef,
  endDateRef,
  startTimeRef,
  endTimeRef,
}: {
  dateTimePickers: DateTimePickerInput[];
  isRealDateTimePicker: boolean;
  control: Control<FormValues, DateTimePickers>;
  setValue: UseFormSetValue<FormValues>;
  validationMethods: ValidationMethods;
  autoFocusNextInput: (currentInput: InputWithAutofocus) => void;
  startDateRef: RefObject<HTMLInputElement>;
  endDateRef: RefObject<HTMLInputElement>;
  startTimeRef: RefObject<HTMLInputElement>;
  endTimeRef: RefObject<HTMLInputElement>;
}) => {
  const [refClicked, setRefClicked] =
    useState<RefObject<HTMLInputElement> | null>(null);

  return isRealDateTimePicker ? (
    <Suspense
      fallback={
        <FakeDateTimePickers
          dateTimePickers={dateTimePickers}
          startDateRef={startDateRef}
          endDateRef={endDateRef}
          startTimeRef={startTimeRef}
          endTimeRef={endTimeRef}
          setRefClicked={setRefClicked}
        />
      }
    >
      <DynamicDateTimePickers
        dateTimePickers={dateTimePickers}
        control={control}
        setValue={setValue}
        validationMethods={validationMethods}
        autoFocusNextInput={autoFocusNextInput}
        startDateRef={startDateRef}
        endDateRef={endDateRef}
        startTimeRef={startTimeRef}
        endTimeRef={endTimeRef}
        refClicked={refClicked}
      />
    </Suspense>
  ) : (
    <FakeDateTimePickers
      dateTimePickers={dateTimePickers}
      startDateRef={startDateRef}
      endDateRef={endDateRef}
      startTimeRef={startTimeRef}
      endTimeRef={endTimeRef}
      setRefClicked={setRefClicked}
    />
  );
};

export default DateTimePickersWithLazyLoading;
