export const MAX_DIFFERENCE_ROUND_TRIP_DATES_THRESHOLD = 90;
export const MAX_DIFFERENCE_ONE_WAY_DATES_THRESHOLD = 5;

export enum DateTimePickersErrors {
  DATE = "dateError",
  TIME = "timeError",
  DATE_TIME = "dateTimeError",
  DATE_IN_PAST = "dateInPastError",
  DATE_TIME_ERROR_ON_END_DATEPICKER = "dateTimeEndDatePickerError",
  NO_ERROR = "noError",
}
