import dayjs from "dayjs";
import { DateTimePickers, FormattedDate } from "@/interfaces/searchBlock";
import { isDateFilled, isTimeFilled } from "../../../helpers/isInputFilled";
import { ValidationMethods } from "../../../validation/isFormValidAndDisplayErrors";

export const times = [
  "00:00",
  "00:30",
  "01:00",
  "01:30",
  "02:00",
  "02:30",
  "03:00",
  "03:30",
  "04:00",
  "04:30",
  "05:00",
  "05:30",
  "06:00",
  "06:30",
  "07:00",
  "07:30",
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
  "23:30",
];

export const getIndexOfLastDisabledTime = (time: Date) => {
  const hours = time.getHours();
  const minutes = time.getMinutes();

  return 2 * hours + ~~(minutes / 30);
};

export type GetTimesInDayHalfHourRatesProps = {
  inputName: DateTimePickers;
  validationMethods: ValidationMethods;
};

export const getTimesInDayHalfHourRates = ({
  inputName,
  validationMethods,
}: GetTimesInDayHalfHourRatesProps) => {
  const { getValues } = validationMethods;
  const now = new Date();

  const dateValue = getValues(inputName);
  const isDateSelected = isDateFilled(dateValue);

  const isSelectedDateToday = isDateSelected && areSameDay(dateValue.date, now);
  const indexOfLastDisabledTime = getIndexOfLastDisabledTime(now);

  return times.map((time, index) => ({
    label: time,
    value: time,
    disabled: isSelectedDateToday ? index <= indexOfLastDisabledTime : false,
  }));
};

export const formatDateAndTimeToDateTime = (dateTime: FormattedDate) => {
  const dateToFormattedString = dayjs(dateTime.date).format("YYYY-MM-DD");

  return isTimeFilled(dateTime)
    ? `${dateToFormattedString}T${dateTime.time}:00`
    : `${dateToFormattedString}T00:00:00`;
};

export const differenceBetweenDatesInDays = (
  startDate: FormattedDate,
  endDate: FormattedDate
) => {
  const formattedStartDate = formatDateAndTimeToDateTime({
    date: startDate.date,
    time: startDate.time,
  });

  const formattedEndDate = formatDateAndTimeToDateTime({
    date: endDate.date,
    time: endDate.time,
  });

  return Math.abs(
    dayjs(formattedEndDate).diff(formattedStartDate, "day", true)
  );
};

export const areSameDay = (date: Date, dateToCompare: Date) =>
  dayjs(date).isSame(dateToCompare, "date");

export const convertCurrentTimeToNearestHalfHour = () => {
  const today = new Date();

  const nearestHalfHourEpochTime = today.setMinutes(
    Math.ceil(today.getMinutes() / 30) * 30
  );

  const nearestHalfHour = new Date(nearestHalfHourEpochTime);

  return dayjs(nearestHalfHour).format("HH:mm");
};
