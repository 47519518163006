import { useEffect, useState } from "react";

const prismeApiUrl = process.env.NEXT_PUBLIC_PRISME_API_URL ?? "";

type AgencyProposition = {
  idAgency: string;
  gpsLat: number;
  gpsLong: number;
  matchedName: string;
  name: string;
};

type AgenciesPropositionResponse = {
  status: string;
  data: AgencyProposition[];
};

const fetchAgenciesProposition = async (description: string) => {
  const response = await fetch(
    `${prismeApiUrl}/agencies?term=${description}&language=fr&trajectType=default`
  );

  return response.json();
};

export const useFetchAutocompleteAgencies = (description: string) => {
  const [agencies, setAgencies] = useState<AgencyProposition[] | undefined>([]);
  useEffect(() => {
    if (description.length >= 1) {
      fetchAgenciesProposition(description)
        .then((response: AgenciesPropositionResponse) => {
          setAgencies(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
      setAgencies([]);
    }

    if (description === "") {
      setAgencies([]);
    }
  }, [description]);

  return agencies;
};
