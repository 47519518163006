const getGooglePlaceDetails = (
  googleMapsPlaceService: google.maps.places.PlacesService,
  id: string
) =>
  new Promise<google.maps.places.PlaceResult | null>((resolve) =>
    googleMapsPlaceService.getDetails({ placeId: id }, (placeDetails) =>
      resolve(placeDetails)
    )
  );

type getGooglePlaceCoordinatesProps = {
  googleMapsPlaceService: google.maps.places.PlacesService;
  googleMapsRefreshSessionToken: () => void;
  placeId: string | null;
  latitude: string | undefined;
  longitude: string | undefined;
};

export const getGooglePlaceCoordinates = async ({
  googleMapsPlaceService,
  googleMapsRefreshSessionToken,
  placeId,
  latitude,
  longitude,
}: getGooglePlaceCoordinatesProps): Promise<{
  computedLatitude: number;
  computedLongitude: number;
}> => {
  if (placeId !== null) {
    const placeDetails = await getGooglePlaceDetails(
      googleMapsPlaceService,
      placeId
    );
    googleMapsRefreshSessionToken();

    return {
      computedLatitude: placeDetails?.geometry?.location?.lat() ?? 0,
      computedLongitude: placeDetails?.geometry?.location?.lng() ?? 0,
    };
  }

  // Case when user is on the resultPage and has not changed the autocomplete inputs
  return {
    computedLatitude: parseFloat(latitude ?? "0"),
    computedLongitude: parseFloat(longitude ?? "0"),
  };
};
