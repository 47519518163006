import classNames from "classnames";
import dayjs from "dayjs";
import { useIntl } from "react-intl";
import { Section } from "@/components/common/section/Section";
import { FormValues } from "@/interfaces/searchBlock";
import { ChevronRight } from "@/public/icons/ChevronRight";
import {
  oneWayInputs,
  roundTripInput,
} from "@/components/common/searchBlock/children/autocomplete/context/autocompleteInputs";
import { dateTimePickers } from "@/components/common/searchBlock/children/dateTimePicker/context/dateTimePickerInputs";
import styles from "./CollapsedSearchBlock.module.scss";

type CollapsedSearchBlockProps = {
  formValues: FormValues;
  scrollToSearchBlock: () => void;
  alwaysCollapseSearchBlock: boolean;
};

const formatDate = (dateTime: { date: Date }) =>
  `${dayjs(dateTime.date).format("ddd DD/MM/YY")}`;

export const CollapsedSearchBlock = ({
  formValues,
  scrollToSearchBlock,
  alwaysCollapseSearchBlock,
}: CollapsedSearchBlockProps) => {
  const intl = useIntl();

  const autocompleteInputs = formValues.isOneWayTrip
    ? oneWayInputs
    : roundTripInput;

  const pickUpAddress = formValues[autocompleteInputs[0].name]?.description;
  const dropoffAddress = formValues.isOneWayTrip
    ? formValues[autocompleteInputs[1].name]?.description
    : formValues[autocompleteInputs[0].name]?.description;

  const pickupDate = formValues[dateTimePickers[0].name] as {
    date: Date;
    time: string;
  };

  const dropoffDate = formValues[dateTimePickers[1].name] as {
    date: Date;
    time: string;
  };

  return (
    <Section
      containerStyle={classNames(
        styles.searchBlockContainer,
        !alwaysCollapseSearchBlock
          ? styles.shownSearchBlock
          : styles.hiddenSearchBlock
      )}
    >
      <div className={styles.innerContainer}>
        <div className={styles.searchBlock}>
          <div className={styles.pickup} onClick={scrollToSearchBlock}>
            <div className={styles.addressContainer}>
              <div className={styles.address}>{pickUpAddress}</div>
              {formValues.isOneWayTrip && (
                <>
                  <div className={styles.separator}>-</div>
                  <div className={styles.address}>{dropoffAddress}</div>
                </>
              )}
            </div>
            <div className={styles.dates}>
              <div className={styles.dateContainer}>
                <div className={styles.date}>{formatDate(pickupDate)}</div>
                <div className={styles.separator}>
                  {intl.formatMessage({ id: "at" })}
                </div>
                <div className={styles.time}>{pickupDate.time}</div>
              </div>
              <div className={styles.iconContainer}>
                <ChevronRight size="14" color="#3f4756" />
              </div>
              <div className={styles.dateContainer}>
                <div className={styles.date}>{formatDate(dropoffDate)}</div>

                <div className={styles.separator}>
                  {intl.formatMessage({ id: "at" })}
                </div>
                <div className={styles.time}>{dropoffDate.time}</div>
              </div>
            </div>
          </div>
          <div
            className={styles.modifyButtonContainer}
            onClick={scrollToSearchBlock}
          >
            <div className={styles.label}>
              {intl.formatMessage({ id: "modify" })}
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};
