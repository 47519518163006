import React, { useState } from "react";
import { usePlacesAutocompleteServiceResponse } from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { Coordinates, FormValues } from "@/interfaces/searchBlock";
import { SearchBlock } from "./SearchBlock";
import { LoadGoogleMapsPlacesScript } from "./LoadGoogleMapsPlacesScript";
import { initialStateValidation } from "./validation/initialStateValidationService";
import usePlacePrediction from "./children/autocomplete/hooks/usePlacePrediction";

type SearchBlockContainerProps = {
  initialState?: FormValues;
  coordinates?: Coordinates;
  loadMapsScriptOnFirstRender?: boolean;
  autoTriggerResearch?: boolean;
  shouldCollapseSearchBlock?: "always" | "whenScrolled" | "never";
  lazyLoadDatePickers?: boolean;
  setIsOneWayTrip?: (value: boolean) => void;
};

export const SearchBlockContainer = ({
  initialState,
  coordinates,
  loadMapsScriptOnFirstRender = false,
  autoTriggerResearch = false,
  shouldCollapseSearchBlock = "never",
  lazyLoadDatePickers = false,
  setIsOneWayTrip,
}: SearchBlockContainerProps) => {
  const [hasStartedLoadingMapsScript, setHasStartedLoadingMapsScript] =
    useState(loadMapsScriptOnFirstRender);
  const [googleMapsPlacesService, setGoogleMapsPlacesService] =
    useState<usePlacesAutocompleteServiceResponse>();

  const placePredictionService = usePlacePrediction();

  // Google maps have a delay to load service
  // To avoid this behavior we have to force a rerendering until the service is loaded
  const [trigger, triggerRerender] = useState(0);

  // check is google placesService is loaded to trigger the rerendering on the first script loading
  // setTimeout to 50ms to minimize google api calls
  if (googleMapsPlacesService?.placesService === null) {
    setTimeout(() => {
      const triggerUpdated = trigger + 1;
      triggerRerender(triggerUpdated);
    }, 50);
  }

  initialState = initialStateValidation({
    initialState: initialState,
  });

  return (
    <>
      <SearchBlock
        initialState={initialState}
        coordinates={coordinates}
        loadGoogleMapsScript={() => {
          setHasStartedLoadingMapsScript(true);
        }}
        placesService={googleMapsPlacesService?.placesService}
        refreshSessionToken={googleMapsPlacesService?.refreshSessionToken}
        googleMapsPredictionService={
          googleMapsPlacesService !== undefined
            ? {
                placePredictions: googleMapsPlacesService.placePredictions,
                getPlacePredictions:
                  googleMapsPlacesService.getPlacePredictions,
                isPlacePredictionsLoading:
                  googleMapsPlacesService.isPlacePredictionsLoading,
              }
            : undefined
        }
        placePredictionService={placePredictionService}
        autoTriggerResearch={autoTriggerResearch}
        shouldCollapseSearchBlock={shouldCollapseSearchBlock}
        lazyLoadDatePickers={lazyLoadDatePickers}
        setIsOneWayTrip={setIsOneWayTrip}
      />
      {hasStartedLoadingMapsScript && (
        <LoadGoogleMapsPlacesScript
          setGoogleMapsPlacesService={setGoogleMapsPlacesService}
        />
      )}
    </>
  );
};
