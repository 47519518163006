import { PlacePredictionService } from "../children/autocomplete/hooks/usePlacePrediction";

type getPlaceCoordinatesProps = {
  placePredictionService: PlacePredictionService | null;
  placeId: string | null;
  latitude: string | undefined;
  longitude: string | undefined;
};

export type PlaceCoordinates = {
  computedLatitude: number;
  computedLongitude: number;
};

export const getPlaceCoordinates = async ({
  placePredictionService,
  placeId,
  latitude,
  longitude,
}: getPlaceCoordinatesProps): Promise<PlaceCoordinates> => {
  if (placePredictionService !== null && placeId !== null) {
    const placeDetails = await placePredictionService.getPlacePredictionDetails(
      placeId
    );

    return {
      computedLatitude: placeDetails?.latitude ?? 0,
      computedLongitude: placeDetails?.longitude ?? 0,
    };
  }

  // Case when user is on the resultPage and has not changed the autocomplete inputs
  return {
    computedLatitude: parseFloat(latitude ?? "0"),
    computedLongitude: parseFloat(longitude ?? "0"),
  };
};
