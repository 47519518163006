import { useCallback, useEffect, useState } from "react";
import useScript from "./useScript";

export type PlacePrediction = {
  placeId: string;
  description: string;
};

export type PlacePredictionDetails = {
  placeId: string;
  description: string;
  latitude: number;
  longitude: number;
};

export type PlacePredictionService = {
  isPlacePredictionLoading: boolean;
  placePredictions: PlacePrediction[];
  getPlacePredictions: (input: string) => void;
  getPlacePredictionDetails: (
    input: string
  ) => Promise<PlacePredictionDetails | undefined>;
};

type woosmapAutocompleteMultiResponse = {
  description: string;
  id: string;
};

type woosmapDetailsLocalitiesResponse = {
  id: string;
  formatted_address: string;
  geometry: {
    location: {
      lat: number;
      lng: number;
    };
  };
};

type woosmapMultisearchService = {
  autocompleteMulti: (
    input: string
  ) => Promise<woosmapAutocompleteMultiResponse[]>;
  detailsLocalities: (
    input: string
  ) => Promise<woosmapDetailsLocalitiesResponse>;
};

declare global {
  interface Window {
    woosmap: {
      multisearch: (
        input: woosmapMultisearchConfig
      ) => woosmapMultisearchService;
    };
  }
}

type woosmapApiConfig = {
  key: string;
  fallbackBreakpoint: number;
  minInputLength?: number;
  params?: {
    types?: string[];
    language: string;
    components: {
      country: string[];
    };
  };
};

type woosmapMultisearchConfig = {
  apiOrder: string[];
  localities?: woosmapApiConfig;
  address?: woosmapApiConfig;
  places?: woosmapApiConfig;
};

const woosmapConfig: woosmapMultisearchConfig = {
  apiOrder: ["localities"],
  localities: {
    key: "woos-4870c95b-705d-3910-8b34-7f17a9e85509",
    fallbackBreakpoint: 0.5,
    minInputLength: 3,
    params: {
      types: [
        "locality",
        "country",
        "address",
        "postal_code",
        "airport",
        "train_station",
      ],
      language: "fr",
      components: { country: ["FR"] },
    },
  },
};

export default function usePlacePrediction(): PlacePredictionService | null {
  const [woosmapService, setWoosmapService] =
    useState<woosmapMultisearchService | null>(null);
  const [placePredictionService, setPlacePredictionService] =
    useState<PlacePredictionService | null>(null);
  const [placePredictions, setPlacePredictions] = useState<PlacePrediction[]>(
    []
  );
  const [isPlacePredictionLoading, setIsPlacePredictionLoading] =
    useState<boolean>(false);

  const woosmapLoaded = useScript(
    "https://sdk.woosmap.com/multisearch/multisearch.js"
  );

  const getPlacePredictions = useCallback(
    (input: string) => {
      if (woosmapService !== null) {
        setIsPlacePredictionLoading(true);
        woosmapService.autocompleteMulti(input).then(
          (results) => {
            const resultDescriptions = results.map((x) => {
              const placePrediction: PlacePrediction = {
                placeId: x.id,
                description: x.description,
              };

              return placePrediction;
            });
            setPlacePredictions(resultDescriptions);
          },
          (error) => {
            console.log(error);

            setPlacePredictions([]);
          }
        );
        setIsPlacePredictionLoading(false);
      }
    },
    [woosmapService]
  );

  const getPlacePredictionDetails = useCallback(
    async (input: string): Promise<PlacePredictionDetails | undefined> => {
      if (woosmapService !== null) {
        const placePredictionDetails = await woosmapService
          .detailsLocalities(input)
          .then(
            (result) => {
              const details: PlacePredictionDetails = {
                placeId: result.id,
                description: result.formatted_address,
                latitude: result.geometry.location.lat,
                longitude: result.geometry.location.lng,
              };

              return details;
            },
            (error) => {
              console.log(error);

              return undefined;
            }
          );

        return placePredictionDetails;
      }
    },
    [woosmapService]
  );

  useEffect(() => {
    if (woosmapLoaded && typeof window !== "undefined") {
      const woosmapMultisearchService: woosmapMultisearchService =
        window.woosmap.multisearch(woosmapConfig);
      setWoosmapService(woosmapMultisearchService);
    }
  }, [woosmapLoaded]);

  useEffect(() => {
    setPlacePredictionService({
      placePredictions: placePredictions,
      getPlacePredictions: getPlacePredictions,
      getPlacePredictionDetails: getPlacePredictionDetails,
      isPlacePredictionLoading: isPlacePredictionLoading,
    });
  }, [
    placePredictions,
    getPlacePredictions,
    getPlacePredictionDetails,
    isPlacePredictionLoading,
  ]);

  return placePredictionService;
}
