import { useMantineTheme } from "@mantine/core";

type RoundTripStartIconProps = {
  size?: number;
  color?: string;
  isError?: boolean;
};

export const RoundTripStartIcon = ({
  size = 22,
  color,
  isError = false,
}: RoundTripStartIconProps) => {
  const theme = useMantineTheme();
  const strokeColor =
    color ?? theme.colors[isError ? "error-red" : "ocean-blue"][0];

  return (
    <svg
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
    >
      <g data-name="invisible box">
        <path
          data-name="Rectangle 1204"
          style={{
            fill: "none",
          }}
          d="M0 0h22v22H0z"
        />
      </g>
      <g data-name="icons Q2">
        <path
          data-name="Trac\xE9 11281"
          d="M20.231 11.846a1.385 1.385 0 1 1-1.385 1.385 1.339 1.339 0 0 1 1.385-1.385m0-1.846a3.231 3.231 0 1 0 3.231 3.231A3.231 3.231 0 0 0 20.231 10z"
          transform="translate(-12.153 -4.385)"
          style={{
            fill: strokeColor,
          }}
        />
        <path
          data-name="Trac\xE9 11282"
          d="M14.846 3.846c3.415 0 6 2.769 6 6.462s-3.969 7.708-6 9.785c-2.031-2.077-6-6.323-6-9.785a6.245 6.245 0 0 1 6-6.462m0-1.846C10.277 2 7 5.739 7 10.308s5.308 9.831 7.2 11.723a.877.877 0 0 0 1.292 0c1.892-1.892 7.2-7.062 7.2-11.723S19.416 2 14.846 2z"
          transform="translate(-6.769 -1.077)"
          style={{
            fill: strokeColor,
          }}
        />
      </g>
      <path
        data-name="Ligne 456"
        transform="translate(11.5 20.5)"
        style={{
          stroke: strokeColor,
          strokeLinecap: "round",
          strokeWidth: "1.5px",
          fill: "none",
        }}
        d="M0 0h8"
      />
    </svg>
  );
};
