type ChevronRightProps = {
  size?: string;
  color?: string;
};

export const ChevronRight = ({
  size = "34",
  color = "#062e5e",
}: ChevronRightProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 34 34"
  >
    <path transform="translate(1 5)" fill="transparent" d="M0 0h29v29H0z" />
    <g data-name="Ellipse 871" fill="#fff" stroke="#f7f7f7">
      <circle cx="17" cy="17" r="17" stroke="none" />
      <circle cx="17" cy="17" r="16.5" fill="none" />
    </g>
    <path
      d="m940.95 1166.491-9.368 8.966a.8.8 0 0 1-1.1 0 .725.725 0 0 1 0-1.057l8.816-8.438-8.816-8.438a.725.725 0 0 1 0-1.057.806.806 0 0 1 1.1 0l9.368 8.966a.727.727 0 0 1 0 1.058z"
      transform="translate(-918.107 -1148.963)"
      fill={color}
      stroke={color}
      strokeWidth=".5px"
    />
  </svg>
);
