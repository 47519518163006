import { ThinBorderStyle, Trips } from "@/interfaces/searchBlock";
import { RoundTripEndIcon } from "@/public/icons/RoundTripEndIcon";
import { RoundTripIcon } from "@/public/icons/RoundTripIcon";
import { RoundTripStartIcon } from "@/public/icons/RoundTripStartIcon";

type AutocompleteInput = {
  name: Trips;
  inputId: string;
  icon: (isError: boolean) => JSX.Element;
  ariaLabel: string;
  placeholder: string;
  thinBorder?: ThinBorderStyle;
};

export const oneWayInputs: AutocompleteInput[] = [
  {
    name: Trips.ONE_WAY_START,
    inputId: "one-way-start",
    icon: (isError: boolean) => <RoundTripStartIcon isError={isError} />,
    ariaLabel: "searchBlockOneWayStartInputLabel",
    placeholder: "searchBlockOneWayStartInputPlaceholder",
    thinBorder: ThinBorderStyle.THIN_BORDER_RIGHT,
  },
  {
    name: Trips.ONE_WAY_END,
    inputId: "one-way-end",
    icon: (isError: boolean) => <RoundTripEndIcon isError={isError} />,
    ariaLabel: "searchBlockOneWayEndInputLabel",
    placeholder: "searchBlockOneWayEndInputPlaceholder",
    thinBorder: ThinBorderStyle.THIN_BORDER_LEFT,
  },
];

export const roundTripInput: AutocompleteInput[] = [
  {
    name: Trips.ROUND_TRIP,
    inputId: "round-trip",
    icon: (isError: boolean) => <RoundTripIcon isError={isError} />,
    ariaLabel: "searchBlockRoundTripInputLabel",
    placeholder: "searchBlockRoundTripInputPlaceholder",
  },
];
