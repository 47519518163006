import { UseFormGetValues, UseFormSetValue } from "react-hook-form";
import { FormValues, SelectedPlace, Trips } from "@/interfaces/searchBlock";
import { isDescriptionFilled } from "../../helpers/isInputFilled";

type PrefillAddressesParams = {
  setValue: UseFormSetValue<FormValues>;
  getValues: UseFormGetValues<FormValues>;
  inputToPrefill: Trips.ROUND_TRIP | Trips.ONE_WAY_START;
};

const isPreviousInputValueARoundTripAgency = (
  inputToPrefill: Trips.ROUND_TRIP | Trips.ONE_WAY_START,
  previousInputValue: SelectedPlace | undefined
) => inputToPrefill === Trips.ONE_WAY_START && previousInputValue?.isAgency;

export const prefillAddresses = ({
  setValue,
  getValues,
  inputToPrefill,
}: PrefillAddressesParams) => {
  const previousInputValue = getValues(
    inputToPrefill === Trips.ROUND_TRIP ? Trips.ONE_WAY_START : Trips.ROUND_TRIP
  );

  if (
    isDescriptionFilled(previousInputValue) &&
    !isPreviousInputValueARoundTripAgency(inputToPrefill, previousInputValue)
  ) {
    setValue(inputToPrefill, previousInputValue);
  }
};
