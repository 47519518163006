import { Input, InputWrapper } from "@mantine/core";
import classNames from "classnames";
import { RefObject } from "react";
import { useIntl } from "react-intl";
import { CalendarIcon } from "@/public/icons/CalendarIcon";
import { DateTimePickers } from "@/interfaces/searchBlock";
import dateTimePickerStyles from "../DateTimePicker.module.scss";
import { DateTimePickerInput } from "../context/dateTimePickerInputs";

const FakeDateTimePickers = ({
  dateTimePickers,
  startDateRef,
  endDateRef,
  startTimeRef,
  endTimeRef,
  setRefClicked,
}: {
  dateTimePickers: DateTimePickerInput[];
  startDateRef: RefObject<HTMLInputElement>;
  endDateRef: RefObject<HTMLInputElement>;
  startTimeRef: RefObject<HTMLInputElement>;
  endTimeRef: RefObject<HTMLInputElement>;
  setRefClicked: (ref: RefObject<HTMLInputElement>) => void;
}) => {
  const intl = useIntl();

  return (
    <>
      {dateTimePickers.map(
        ({
          datePickerAriaLabel,
          datePickerPlaceholder,
          selectAriaLabel,
          selectPlaceholder,
          name,
        }) => (
          <InputWrapper
            key={datePickerAriaLabel}
            classNames={{
              root: dateTimePickerStyles.inputWrapperRoot,
            }}
          >
            <div className={dateTimePickerStyles.dateTimePickerContainer}>
              <Input
                aria-label={intl.formatMessage({
                  id: datePickerAriaLabel,
                })}
                placeholder={intl.formatMessage({
                  id: datePickerPlaceholder,
                })}
                classNames={{
                  input: classNames(
                    dateTimePickerStyles.datePickerWrapper,
                    dateTimePickerStyles.datePickerInput,
                    dateTimePickerStyles.loading
                  ),
                  icon: dateTimePickerStyles.datePickerIcon,
                  wrapper: dateTimePickerStyles.fullWidth,
                }}
                icon={<CalendarIcon isError={false} />}
                onClick={() =>
                  setRefClicked(
                    name === DateTimePickers.START_DATE
                      ? startDateRef
                      : endDateRef
                  )
                }
              />
              <Input
                aria-label={intl.formatMessage({
                  id: selectAriaLabel,
                })}
                placeholder={intl.formatMessage({
                  id: selectPlaceholder,
                })}
                classNames={{
                  input: classNames(
                    dateTimePickerStyles.selectInput,
                    dateTimePickerStyles.loading
                  ),
                  rightSection: dateTimePickerStyles.selectRightSection,
                }}
                onClick={() =>
                  setRefClicked(
                    name === DateTimePickers.START_DATE
                      ? startTimeRef
                      : endTimeRef
                  )
                }
              />
            </div>
          </InputWrapper>
        )
      )}
    </>
  );
};

export default FakeDateTimePickers;
