import { Trips } from "@/interfaces/searchBlock";
import { ValidationMethods } from "../isFormValidAndDisplayErrors";

type IsAutocompleteFieldErrorProps = {
  fieldName: Trips.ROUND_TRIP | Trips.ONE_WAY_START | Trips.ONE_WAY_END;
  validationMethods: Pick<ValidationMethods, "getFieldState">;
};

export const isAutocompleteFieldError = ({
  fieldName,
  validationMethods,
}: IsAutocompleteFieldErrorProps) => {
  return validationMethods.getFieldState(fieldName).error !== undefined;
};

type IsAnyAutocompleteFieldError = {
  validationMethods: Pick<ValidationMethods, "formState">;
};

export const isAnyAutocompleteFieldError = ({
  validationMethods,
}: IsAnyAutocompleteFieldError) => {
  const errorKeys = Object.keys(validationMethods.formState.errors);

  return Object.values(Trips).some((trip) => errorKeys.includes(trip));
};
