import { Autocomplete, useMantineTheme } from "@mantine/core";
import { ReactNode, RefObject, useEffect, useState } from "react";
import { Control, Controller } from "react-hook-form";

import classNames from "classnames";
import { useMediaQuery } from "@mantine/hooks";
import { usePlacesAutocompleteServiceResponse } from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useIntl } from "react-intl";
import { getBreakpoint } from "@/utils/mantine";
import {
  FormValues,
  SelectedPlace,
  ThinBorderStyle,
  Trips,
} from "@/interfaces/searchBlock";
import { PlacesNotFound } from "./placesNotFound/PlacesNotFound";
import { useFetchAutocompleteAgencies } from "./hooks/useFetchAutocompleteAgencies";

import styles from "./GoogleMapsAutocomplete.module.scss";
import { InputWithAutofocus } from "../../helpers/autoFocusNextInput";
import { ValidationMethods } from "../../validation/isFormValidAndDisplayErrors";
import AutoCompleteItem, {
  AutoCompleteItemProps,
  AutocompleteType,
} from "./autocompleteItem/AutocompleteItem";
import { blurInput } from "../../helpers/isInputFilled";

export type AutocompleteEvent = {
  target: {
    name: Trips;
    value?: SelectedPlace;
  };
  type: string;
};

type AutocompletePrediction = google.maps.places.AutocompletePrediction & {
  description: string;
  place_id: string;
};

type SearchBlockAutocompleteProps = {
  inputId: string;
  ariaLabel: string;
  placeholder: string;
  icon: ReactNode;
  thinBorder?: ThinBorderStyle;
  googleMapsPredictionService?: Pick<
    usePlacesAutocompleteServiceResponse,
    "placePredictions" | "getPlacePredictions" | "isPlacePredictionsLoading"
  >;
  name: Trips;
  control: Control<FormValues, Trips>;
  validationMethods: ValidationMethods;
  tripRef: RefObject<HTMLInputElement>;
  displayAgencyPropositions: boolean;
  autoFocusNextInput: (currentInput: InputWithAutofocus) => void;
};

export const GoogleMapsAutocomplete = ({
  inputId,
  ariaLabel,
  placeholder,
  icon,
  thinBorder,
  googleMapsPredictionService,
  name,
  control,
  validationMethods,
  tripRef,
  displayAgencyPropositions,
  autoFocusNextInput,
}: SearchBlockAutocompleteProps) => {
  const intl = useIntl();
  const theme = useMantineTheme();
  const isTabletOrDesktop = useMediaQuery(getBreakpoint(theme.breakpoints.md));
  const isDesktop = useMediaQuery(getBreakpoint(theme.breakpoints.lg));
  const [autocompleteInputValue, setAutocompleteInputValue] = useState("");
  const autocompleteAgenciesProposition = useFetchAutocompleteAgencies(
    autocompleteInputValue
  );

  // Hide dropdown before modifications to avoid empty display
  const [isModified, setIsModified] = useState(false);

  const maxAgencyPropositions = isTabletOrDesktop ? 8 : 4;
  const maxAddressPropositions = 4;

  const placePredictions = googleMapsPredictionService?.placePredictions;
  const isPlacePredictionsLoading =
    googleMapsPredictionService?.isPlacePredictionsLoading ?? true;

  const [autocompleteItems, setAutocompleteItems] = useState<
    AutoCompleteItemProps[]
  >([]);

  useEffect(() => {
    let autocompleteAgenciesItem: AutoCompleteItemProps[] = [];
    if (autocompleteAgenciesProposition !== undefined) {
      const limitedAutocompleteAgenciesProposition =
        autocompleteAgenciesProposition.filter(
          (_, index) => index < maxAgencyPropositions
        );

      autocompleteAgenciesItem = limitedAutocompleteAgenciesProposition.map(
        (agenciesPrediction, index) => {
          const isFirstAutocompleteItem = index === 0;

          return {
            value: agenciesPrediction.name,
            placeId: null,
            shouldDisplayGoogleLogo: isFirstAutocompleteItem,
            type: AutocompleteType.AGENCY,
            group: intl.formatMessage({ id: AutocompleteType.AGENCY }),
            latitude: agenciesPrediction.gpsLat.toString(),
            longitude: agenciesPrediction.gpsLong.toString(),
          };
        }
      );
    }

    let placePredictionItems: AutoCompleteItemProps[] = [];
    if (placePredictions !== undefined) {
      const seenDescriptions = new Set();
      const limitedPlacePredictions = placePredictions
        // TODO: remove this filter when googleMaps API has been replaced
        .filter((el) => {
          const isDuplicate = seenDescriptions.has(el.description);
          seenDescriptions.add(el.description);

          return !isDuplicate;
        })
        .slice(0, maxAddressPropositions);
      placePredictionItems = limitedPlacePredictions.map(
        (placePrediction: AutocompletePrediction) => {
          return {
            value: placePrediction.description,
            placeId: placePrediction.place_id,
            shouldDisplayGoogleLogo: false,
            type: AutocompleteType.ADDRESS,
            group: intl.formatMessage({ id: AutocompleteType.ADDRESS }),
          };
        }
      );
    }

    const newAutocompleteItems = [
      ...(displayAgencyPropositions ? autocompleteAgenciesItem : []),
      ...placePredictionItems,
    ];

    setAutocompleteItems(newAutocompleteItems);
  }, [
    placePredictions,
    autocompleteAgenciesProposition,
    maxAgencyPropositions,
    maxAddressPropositions,
    displayAgencyPropositions,
    intl,
  ]);

  const disableDefaultMantineExactFilter = () => true;

  const [autocompleteDelayed, setAutocompleteDelayed] =
    useState<boolean>(false);
  const [autocompleteTimeout, setAutocompleteTimeout] =
    useState<NodeJS.Timeout>();
  const delayAutocompleteValue = (event: string) => {
    if (autocompleteDelayed && autocompleteTimeout) {
      clearTimeout(autocompleteTimeout);
    }

    setAutocompleteDelayed(true);
    setAutocompleteTimeout(
      setTimeout(() => {
        setAutocompleteInputValue(event);
        setAutocompleteDelayed(false);
      }, 250)
    );
  };

  return (
    <Controller
      render={({ field, fieldState: { error } }) => {
        const shouldHideDropdown =
          isPlacePredictionsLoading ||
          (field.value?.description.length !== undefined &&
            field.value.description.length < 3) ||
          !isModified;

        const shouldDisplayError = error !== undefined;

        return (
          <Autocomplete
            {...field}
            ref={tripRef}
            value={field.value?.description}
            id={inputId}
            icon={icon}
            placeholder={placeholder}
            aria-label={ariaLabel}
            error={shouldDisplayError}
            onBlur={field.onBlur}
            dropdownPosition="bottom"
            limit={maxAgencyPropositions + maxAddressPropositions}
            filter={disableDefaultMantineExactFilter}
            wrapperProps={{ "data-cy": `autocomplete-${inputId}` }}
            styles={{
              dropdown: {
                maxHeight: isDesktop ? "" : "35rem",
                "& .mantine-Text-root": {
                  fontSize: 12,
                  color: "#0069b4",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  letterSpacing: "0.12rem",
                },
              },
            }}
            classNames={{
              input: classNames({
                [styles.input]: true,
                [styles.error]: shouldDisplayError,
                [styles.thinBorderLeft]:
                  thinBorder === ThinBorderStyle.THIN_BORDER_LEFT &&
                  isTabletOrDesktop,
                [styles.thinBorderRight]:
                  thinBorder === ThinBorderStyle.THIN_BORDER_RIGHT &&
                  isTabletOrDesktop,
              }),
              label: styles.label,
              hovered: styles.hovered,
              dropdown: shouldHideDropdown
                ? styles.dropdownHidden
                : styles.dropdownShown,
              icon: styles.icon,
            }}
            itemComponent={AutoCompleteItem}
            nothingFound={<PlacesNotFound />}
            variant="unstyled"
            onChange={(event) => {
              setIsModified(true);
              googleMapsPredictionService?.getPlacePredictions({
                input: event,
              });
              field.onChange({
                description: event,
                placeId: null,
              });
              delayAutocompleteValue(event);
            }}
            onItemSubmit={(selectedPlace: {
              placeId: string;
              value: string;
              latitude: string;
              longitude: string;
              type: string;
            }) => {
              field.onChange({
                description: selectedPlace.value,
                placeId: selectedPlace.placeId,
                latitude: selectedPlace.latitude,
                longitude: selectedPlace.longitude,
                isAgency: selectedPlace.type === AutocompleteType.AGENCY,
              });

              blurInput(tripRef);
              autoFocusNextInput(name);

              if (!isTabletOrDesktop) {
                tripRef.current?.scrollIntoView({
                  behavior: "smooth",
                });
              }
            }}
            data={autocompleteItems}
            onFocus={() => {
              if (field.value !== undefined) {
                googleMapsPredictionService?.getPlacePredictions({
                  input: field.value.description,
                });
              }
            }}
          />
        );
      }}
      control={control}
      name={name}
      rules={{
        onChange: (event: AutocompleteEvent) => {
          validationMethods.clearErrors(event.target.name);
        },
      }}
    />
  );
};

GoogleMapsAutocomplete.displayName = "SearchBlockTextInput";

export default GoogleMapsAutocomplete;
