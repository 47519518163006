import { Checkbox } from "@mantine/core";
import { Control, Controller, UseFormSetValue } from "react-hook-form";
import { useIntl } from "react-intl";
import classNames from "classnames";
import { FormValues, Trips } from "@/interfaces/searchBlock";

import styles from "./SearchBlockCheckbox.module.scss";
import { ValidationMethods } from "../../validation/isFormValidAndDisplayErrors";
import { handleOnChangeDateTimePickerError } from "../../validation/dateTimePicker/dateTimePickerValidationService";
import { prefillAddresses } from "./prefillAddresses";

type SearchBlockCheckboxProps = {
  control: Control<FormValues, "isOneWayTrip">;
  validationMethods: ValidationMethods;
  disabled: boolean;
  setValue: UseFormSetValue<FormValues>;
};
export const SearchBlockCheckbox = ({
  control,
  validationMethods,
  disabled,
  setValue,
}: SearchBlockCheckboxProps) => {
  const intl = useIntl();
  const { clearErrors } = validationMethods;

  return (
    <Controller
      control={control}
      name="isOneWayTrip"
      render={({ field }) => (
        <Checkbox
          disabled={disabled}
          checked={field.value}
          radius="xs"
          onChange={() => {
            clearErrors();
            handleOnChangeDateTimePickerError({
              setValue,
              isOneWayTrip: !field.value,
              validationMethods,
            });
            prefillAddresses({
              setValue,
              getValues: validationMethods.getValues,
              inputToPrefill: field.value
                ? Trips.ROUND_TRIP
                : Trips.ONE_WAY_START,
            });
            field.onChange(!field.value);
          }}
          label={intl.formatMessage({
            id: "searchBlockCheckboxLabel",
          })}
          classNames={{
            label: classNames(styles.label, {
              [styles.disabledLabel]: disabled,
              [styles.checkedLabel]: field.value,
              [styles.uncheckedLabel]: !field.value,
            }),
            input: disabled
              ? styles.disabledCheckboxInput
              : styles.checkboxInput,
          }}
        />
      )}
    />
  );
};
