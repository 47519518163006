import { useMantineTheme } from "@mantine/core";

type CalendarIconProps = {
  isError: boolean;
};

export const CalendarIcon = ({ isError }: CalendarIconProps) => {
  const theme = useMantineTheme();
  const strokeColor = theme.colors[isError ? "error-red" : "ocean-blue"][0];

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={22} height={22}>
      <path
        data-name="Rectangle 892"
        style={{
          fill: "none",
        }}
        d="M0 0h22v22H0z"
      />
      <g
        data-name="Rectangle 893"
        transform="translate(2 3.5)"
        style={{
          stroke: strokeColor,
          strokeWidth: "1.8px",
          fill: "none",
        }}
      >
        <rect
          width={18}
          height={16}
          rx={4}
          style={{
            stroke: "none",
          }}
          stroke="none"
        />
        <rect
          x={0.9}
          y={0.9}
          width={16.2}
          height={14.2}
          rx={3.1}
          style={{
            fill: "none",
          }}
        />
      </g>
      <path
        data-name="Ligne 250"
        transform="translate(3.5 7.868)"
        style={{
          stroke: strokeColor,
          strokeWidth: "1.8px",
          fill: "none",
        }}
        d="M0 0h15"
      />
      <path
        data-name="Ligne 248"
        transform="translate(6.95 2.5)"
        style={{
          strokeLinecap: "round",
          stroke: strokeColor,
          strokeWidth: "1.8px",
          fill: "none",
        }}
        d="M0 0v3"
      />
      <path
        data-name="Ligne 249"
        transform="translate(15.05 2.5)"
        style={{
          strokeLinecap: "round",
          stroke: strokeColor,
          strokeWidth: "1.8px",
          fill: "none",
        }}
        d="M0 0v3"
      />
      <g data-name="Groupe 8496">
        <g
          data-name="Ellipse 409"
          style={{
            stroke: strokeColor,
            strokeWidth: "1.8px",
            fill: "none",
          }}
          transform="translate(6 10.115)"
        >
          <circle
            cx={1}
            cy={1}
            r={1}
            style={{
              stroke: "none",
            }}
            stroke="none"
          />
          <circle
            cx={1}
            cy={1}
            r={0.1}
            style={{
              fill: "none",
            }}
          />
        </g>
        <g
          data-name="Ellipse 410"
          transform="translate(10 10.115)"
          style={{
            stroke: strokeColor,
            strokeWidth: "1.8px",
            fill: "none",
          }}
        >
          <circle
            cx={1}
            cy={1}
            r={1}
            style={{
              stroke: "none",
            }}
            stroke="none"
          />
          <circle
            cx={1}
            cy={1}
            r={0.1}
            style={{
              fill: "none",
            }}
          />
        </g>
        <g
          data-name="Ellipse 411"
          transform="translate(14 10.115)"
          style={{
            stroke: strokeColor,
            strokeWidth: "1.8px",
            fill: "none",
          }}
        >
          <circle
            cx={1}
            cy={1}
            r={1}
            style={{
              stroke: "none",
            }}
            stroke="none"
          />
          <circle
            cx={1}
            cy={1}
            r={0.1}
            style={{
              fill: "none",
            }}
          />
        </g>
        <g
          data-name="Ellipse 412"
          transform="translate(6 14.115)"
          style={{
            stroke: strokeColor,
            strokeWidth: "1.8px",
            fill: "none",
          }}
        >
          <circle
            cx={1}
            cy={1}
            r={1}
            style={{
              stroke: "none",
            }}
            stroke="none"
          />
          <circle
            cx={1}
            cy={1}
            r={0.1}
            style={{
              fill: "none",
            }}
          />
        </g>
        <g
          data-name="Ellipse 413"
          transform="translate(10 14.115)"
          style={{
            stroke: strokeColor,
            strokeWidth: "1.8px",
            fill: "none",
          }}
        >
          <circle
            cx={1}
            cy={1}
            r={1}
            style={{
              stroke: "none",
            }}
            stroke="none"
          />
          <circle
            cx={1}
            cy={1}
            r={0.1}
            style={{
              fill: "none",
            }}
          />
        </g>
        <g
          data-name="Ellipse 414"
          transform="translate(14 14.115)"
          style={{
            stroke: strokeColor,
            strokeWidth: "1.8px",
            fill: "none",
          }}
        >
          <circle
            cx={1}
            cy={1}
            r={1}
            style={{
              stroke: "none",
            }}
            stroke="none"
          />
          <circle
            cx={1}
            cy={1}
            r={0.1}
            style={{
              fill: "none",
            }}
          />
        </g>
      </g>
    </svg>
  );
};
