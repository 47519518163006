import { useMantineTheme } from "@mantine/core";

type RoundTripEndIconProps = {
  size?: number;
  color?: string;
  isError?: boolean;
};

export const RoundTripEndIcon = ({
  size = 22,
  color,
  isError = false,
}: RoundTripEndIconProps) => {
  const theme = useMantineTheme();
  const strokeColor =
    color ?? theme.colors[isError ? "error-red" : "ocean-blue"][0];

  return (
    <svg
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
    >
      <g data-name="invisible box">
        <path
          data-name="Rectangle 1204"
          style={{
            fill: "none",
          }}
          d="M0 0h22v22H0z"
        />
      </g>
      <g data-name="icons Q2">
        <path
          data-name="Trac\xE9 11281"
          d="M20.231 11.846a1.385 1.385 0 1 0 1.385 1.385 1.339 1.339 0 0 0-1.385-1.385m0-1.846A3.231 3.231 0 1 1 17 13.231 3.231 3.231 0 0 1 20.231 10z"
          transform="translate(-6.308 -4.385)"
          style={{
            fill: strokeColor,
          }}
        />
        <path
          data-name="Trac\xE9 11282"
          d="M14.846 3.846c-3.415 0-6 2.769-6 6.462s3.969 7.708 6 9.785c2.031-2.077 6-6.323 6-9.785a6.245 6.245 0 0 0-6-6.462m0-1.846c4.569 0 7.846 3.739 7.846 8.308s-5.308 9.831-7.2 11.723a.877.877 0 0 1-1.292 0C12.308 20.139 7 14.97 7 10.308S10.277 2 14.846 2z"
          transform="translate(-.924 -1.077)"
          style={{
            fill: strokeColor,
          }}
        />
      </g>
      <path
        data-name="Ligne 456"
        transform="translate(2.5 20.5)"
        style={{
          stroke: strokeColor,
          strokeLinecap: "round",
          strokeWidth: "1.5px",
          fill: "none",
        }}
        d="M8 0H0"
      />
    </svg>
  );
};
