import { FormValues } from "@/interfaces/searchBlock";
import { isDateInPast } from "./dateTimePicker/dateTimePickerValidationService";

type InitialStateValidationProps = {
  initialState?: FormValues;
};

export const initialStateValidation = ({
  initialState,
}: InitialStateValidationProps): FormValues | undefined => {
  if (initialState) {
    if (isDateInPast(initialState.startDate?.date)) {
      initialState.startDate = { date: null, time: null };
    }

    if (isDateInPast(initialState.endDate?.date)) {
      initialState.endDate = { date: null, time: null };
    }
  }

  return initialState;
};
