import {
  Coordinates,
  DisponibilitiesQueryParams,
  RideType,
  ValidFormValues,
  ValidOneWayFormValues,
} from "@/interfaces/searchBlock";
import { SelectedAgency, SelectedPlace } from "@/interfaces/searchBlock";
import { formatDateAndTimeToDateTime } from "../children/dateTimePicker/helpers/timeSelector";
import { VehicleType } from "../children/vehicleTypeSelection/VehicleTypeSelection";
// import { getGooglePlaceCoordinates } from "./getGooglePlaceCoordinates";
import { getPlaceCoordinates } from "./getPlaceCoordinates";
import { PlacePredictionService } from "../children/autocomplete/hooks/usePlacePrediction";
import { getGooglePlaceCoordinates } from "./getGooglePlaceCoordinates";
import { computeOneWayStartCoordinates } from "./computeOneWayStartCoordinates";

const showGooglePlacePrediction =
  process.env.NEXT_PUBLIC_SHOW_GOOGLE_PLACE_PREDICTION ?? "true";

const maxAgenciesNumberByRideType = {
  [RideType.ROUND_TRIP]: 4,
  [RideType.ONE_WAY]: 3,
};

const vehicleTypeParams = {
  [VehicleType.CAR]: { VehicleType: VehicleType.CAR },
  [VehicleType.TRUCK]: { VehicleType: VehicleType.TRUCK },
  [VehicleType.CAR_WITHOUT_LICENCE]: {
    VehicleType: VehicleType.CAR,
    IsOnlyWithoutLicense: true,
  },
};

const isOneWayTrip = (
  formValues: ValidFormValues
): formValues is ValidOneWayFormValues => formValues.isOneWayTrip;

const isAgencyPlace = (
  selectedPlace: SelectedPlace | null
): selectedPlace is SelectedAgency => selectedPlace?.isAgency ?? false;

export const computeDisponibilitiesQueryParams = async (
  placePredictionService: PlacePredictionService | null,
  googleMapsPlaceService: google.maps.places.PlacesService,
  googleMapsRefreshSessionToken: () => void,
  formValues: ValidFormValues,
  coordinates?: Coordinates
) => {
  const { startSelectedPlace, endSelectedPlace } = isOneWayTrip(formValues)
    ? {
        startSelectedPlace: formValues.oneWayStart,
        endSelectedPlace: formValues.oneWayEnd,
      }
    : {
        startSelectedPlace: formValues.roundTrip,
        endSelectedPlace: null,
      };

  const { startPlaceId, agencyLatitude, agencyLongitude } = isAgencyPlace(
    startSelectedPlace
  )
    ? {
        startPlaceId: null,
        agencyLatitude: startSelectedPlace.latitude,
        agencyLongitude: startSelectedPlace.longitude,
      }
    : {
        startPlaceId: startSelectedPlace.placeId,
        agencyLatitude: undefined,
        agencyLongitude: undefined,
      };

  const pickupRoundTripMaxNumberOfAgencies =
    maxAgenciesNumberByRideType[RideType.ROUND_TRIP];
  const radius = 50;

  const endPlaceId = isAgencyPlace(endSelectedPlace)
    ? null
    : endSelectedPlace?.placeId ?? null;

  const startPlaceCoordinates =
    showGooglePlacePrediction === "true"
      ? await getGooglePlaceCoordinates({
          googleMapsPlaceService,
          googleMapsRefreshSessionToken,
          placeId: startPlaceId,
          latitude: agencyLatitude ?? coordinates?.pickupLatitude,
          longitude: agencyLongitude ?? coordinates?.pickupLongitude,
        })
      : await getPlaceCoordinates({
          placePredictionService,
          placeId: startPlaceId,
          latitude: agencyLatitude ?? coordinates?.pickupLatitude,
          longitude: agencyLongitude ?? coordinates?.pickupLongitude,
        });

  let disponibilitiesQueryParams: DisponibilitiesQueryParams = {
    RideType: RideType.ROUND_TRIP,
    "Pickup.Latitude": startPlaceCoordinates.computedLatitude,
    "Pickup.Longitude": startPlaceCoordinates.computedLongitude,
    // TODO : export this parameter to Strapi
    "Pickup.MaxNumberOfAgencies": pickupRoundTripMaxNumberOfAgencies,
    Radius: radius,
    ...vehicleTypeParams[formValues.vehicleType],
    PickupDate: formatDateAndTimeToDateTime(formValues.startDate),
    DropoffDate: formatDateAndTimeToDateTime(formValues.endDate),
    pickupDescription: isOneWayTrip(formValues)
      ? formValues.oneWayStart.description
      : formValues.roundTrip.description,
    isAgency: isAgencyPlace(startSelectedPlace),
  };

  if (isOneWayTrip(formValues)) {
    const endPlaceCoordinates =
      showGooglePlacePrediction === "true"
        ? await getGooglePlaceCoordinates({
            googleMapsPlaceService,
            googleMapsRefreshSessionToken,
            placeId: endPlaceId,
            latitude: coordinates?.dropoffLatitude,
            longitude: coordinates?.dropoffLongitude,
          })
        : await getPlaceCoordinates({
            placePredictionService,
            placeId: endPlaceId,
            latitude: coordinates?.dropoffLatitude,
            longitude: coordinates?.dropoffLongitude,
          });

    const computedStartPlaceCoordinates = computeOneWayStartCoordinates(
      startSelectedPlace,
      startPlaceCoordinates
    );

    disponibilitiesQueryParams = {
      ...disponibilitiesQueryParams,
      RideType: RideType.ONE_WAY,
      "Pickup.MaxNumberOfAgencies":
        maxAgenciesNumberByRideType[RideType.ONE_WAY],
      ["Pickup.Latitude"]: computedStartPlaceCoordinates.computedLatitude,
      ["Pickup.Longitude"]: computedStartPlaceCoordinates.computedLongitude,
      ["Dropoff.Latitude"]: endPlaceCoordinates.computedLatitude,
      ["Dropoff.Longitude"]: endPlaceCoordinates.computedLongitude,
      ["Dropoff.MaxNumberOfAgencies"]:
        maxAgenciesNumberByRideType[RideType.ONE_WAY],
      dropoffDescription: formValues.oneWayEnd.description,
      isAgency: false,
    };
  }

  return disponibilitiesQueryParams;
};
