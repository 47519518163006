import { RefObject } from "react";
import { FormattedDate, SelectedPlace } from "@/interfaces/searchBlock";

export const isDateFilled = (
  dateTimeValue: FormattedDate | undefined
): dateTimeValue is { date: Date; time?: string } =>
  dateTimeValue?.date !== undefined && dateTimeValue.date !== null;

export const isTimeFilled = (
  dateTimeValue: FormattedDate | undefined
): dateTimeValue is { date?: Date; time: string } =>
  dateTimeValue?.time !== undefined && dateTimeValue.time !== null;

export const isDescriptionFilled = (
  autocompleteValue: SelectedPlace | undefined
) => autocompleteValue !== undefined && autocompleteValue.description !== "";

export const blurInput = (ref: RefObject<HTMLInputElement>) =>
  setTimeout(() => ref.current?.blur(), 10);
