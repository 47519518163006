import Image from "next/image";
import {
  Control,
  Controller,
  UseFormGetValues,
  UseFormSetValue,
} from "react-hook-form";
import { useIntl } from "react-intl";
import { RefObject, useRef } from "react";
import { useEffect } from "react";
import truckPic from "@/public/images/truck.png";
import carPic from "@/public/images/car.png";
import carWithoutLicencePic from "@/public/images/car-without-licence.png";
import { FormValues } from "@/interfaces/searchBlock";
import styles from "./VehicleTypeSelection.module.scss";
import { triggerFormSubmit } from "../../helpers/triggerFormSubmit";
import { isAgencyPlace } from "../../helpers/isAgencyPlace";

export enum VehicleType {
  TRUCK = "Truck",
  CAR = "Car",
  CAR_WITHOUT_LICENCE = "WithoutLicence",
}

type VehicleTypeSelectionProps = {
  control: Control<FormValues, "vehicleType">;
  setValue: UseFormSetValue<FormValues>;
  getValues: UseFormGetValues<FormValues>;
  formRef: RefObject<HTMLFormElement>;
  autoTriggerResearch: boolean;
  isOneWayTrip: boolean;
};

export const VehicleTypeSelection = ({
  control,
  setValue,
  getValues,
  formRef,
  autoTriggerResearch,
  isOneWayTrip,
}: VehicleTypeSelectionProps) => {
  const intl = useIntl();

  const typeButtonList = [
    {
      src: carPic,
      alt: "Picture of car",
      width: 51,
      height: 26,
      type: VehicleType.CAR,
      typeLabel: "vehicleTypeCars",
      ref: useRef<HTMLButtonElement>(null),
    },
    {
      src: truckPic,
      alt: "Picture of truck",
      width: 57,
      height: 26,
      type: VehicleType.TRUCK,
      typeLabel: "vehicleTypeTrucks",
      ref: useRef<HTMLButtonElement>(null),
    },
    {
      src: carWithoutLicencePic,
      alt: "Picture of car without licence",
      width: 44,
      height: 26,
      type: VehicleType.CAR_WITHOUT_LICENCE,
      typeLabel: "vehicleTypeCarsWithoutLicence",
      ref: useRef<HTMLButtonElement>(null),
    },
  ];

  useEffect(() => {
    if (isOneWayTrip) {
      setValue("vehicleType", VehicleType.TRUCK);
    }
  }, [isOneWayTrip, setValue]);

  return (
    <Controller
      render={({ field }) => (
        <div className={styles.overflowContainer}>
          <div className={styles.buttonsContainer}>
            {typeButtonList.map((typeButton) => (
              <button
                type="button"
                key={typeButton.type}
                className={
                  field.value === typeButton.type
                    ? `${styles.buttonContainer} ${styles.active}`
                    : styles.buttonContainer
                }
                onClick={() => {
                  field.onChange(typeButton.type);
                  typeButton.ref.current?.scrollIntoView({
                    inline: "center",
                    behavior: "smooth",
                    block: "nearest",
                  });

                  if (typeButton.type === VehicleType.CAR_WITHOUT_LICENCE) {
                    setValue("isOneWayTrip", false);

                    const roundTripValue = getValues("roundTrip");

                    if (roundTripValue && isAgencyPlace(roundTripValue)) {
                      setValue("roundTrip", {
                        description: "",
                        placeId: null,
                        isAgency: false,
                      });
                    }
                  }

                  if (autoTriggerResearch) {
                    triggerFormSubmit(formRef);
                  }
                }}
                ref={typeButton.ref}
                data-cy={typeButton.type}
              >
                <div className={styles.buttonContent}>
                  <Image
                    src={typeButton.src}
                    alt={typeButton.alt}
                    layout="fixed"
                    sizes={`(max-width: 51px) ${
                      typeButton.width
                    }wv,(max-height: 26px) ${typeButton.height}wv,${
                      typeButton.height * 2
                    }wv`}
                  />

                  <span className={styles.buttonText}>
                    {intl.formatMessage({
                      id: typeButton.typeLabel,
                    })}
                  </span>
                </div>
                <div className={styles.underline} />
              </button>
            ))}
          </div>
        </div>
      )}
      control={control}
      name="vehicleType"
    />
  );
};
