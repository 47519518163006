import { useIntl } from "react-intl";

export const PlacesNotFound = () => {
  const intl = useIntl();

  return (
    <p>
      {intl.formatMessage({
        id: "searchBlockAutocompletePlacesNotFound",
      })}
    </p>
  );
};
