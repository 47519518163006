import { RefObject } from "react";
import { UseFormGetValues } from "react-hook-form";
import { DateTimePickers, FormValues, Trips } from "@/interfaces/searchBlock";
import {
  isDateFilled,
  isDescriptionFilled,
  isTimeFilled,
} from "./isInputFilled";

export type InputWithAutofocus = Trips | "startDate" | "startTime" | "endDate";

type AutoFocusNextInputParams = {
  getValues: UseFormGetValues<FormValues>;
  endTripRef: RefObject<HTMLInputElement>;
  startDateRef: RefObject<HTMLInputElement>;
  startTimeRef: RefObject<HTMLInputElement>;
  endDateRef: RefObject<HTMLInputElement>;
  endTimeRef: RefObject<HTMLInputElement>;
};

export const clickOnRef = (ref: RefObject<HTMLInputElement>) => {
  // The only way to autofocus the next input is to trigger a click on input 2 when we click on input 1
  // So that means two clicks exactly at the same time
  // => the behavior is inconsistent depending on the order in which both clicks are handled
  // => We use setTimeout to delay the second click to avoid that.
  setTimeout(() => ref.current?.focus(), 10);
  setTimeout(() => ref.current?.click(), 10);
};

export const getAutoFocusNextInput =
  ({
    getValues,
    endTripRef,
    startDateRef,
    startTimeRef,
    endDateRef,
    endTimeRef,
  }: AutoFocusNextInputParams) =>
  (currentInput: InputWithAutofocus) => {
    switch (currentInput) {
      case Trips.ROUND_TRIP:
        if (!isDateFilled(getValues(DateTimePickers.START_DATE))) {
          clickOnRef(startDateRef);
        }

        break;
      case Trips.ONE_WAY_START:
        if (!isDescriptionFilled(getValues(Trips.ONE_WAY_END))) {
          clickOnRef(endTripRef);
        }

        break;
      case Trips.ONE_WAY_END:
        if (!isDateFilled(getValues(DateTimePickers.START_DATE))) {
          clickOnRef(startDateRef);
        }

        break;
      case "startDate":
        if (!isTimeFilled(getValues(DateTimePickers.START_DATE))) {
          clickOnRef(startTimeRef);
        }

        break;
      case "startTime":
        if (!isDateFilled(getValues(DateTimePickers.END_DATE))) {
          clickOnRef(endDateRef);
        }

        break;
      case "endDate":
        if (!isTimeFilled(getValues(DateTimePickers.END_DATE))) {
          clickOnRef(endTimeRef);
        }

        break;
    }
  };
